import React from "react";
import ClipLoader from 'react-spinners/ClipLoader';

import ApiStorage from "../ApiStorage";

const Loading = (loading) => {
    return (
        <ClipLoader
            size={40}
            color={"#CFAF4E"}
            loading={loading}
        />
    )
};
const RedirectPage = ( props ) => {
    React.useEffect(() => {
        const queryParams = new URLSearchParams(props.location.search);
        const params = {
            redirectPath: queryParams.get("redirectPath"),
            userId: queryParams.get("userId"),
            authToken: queryParams.get("authToken")
        };
        if (!params.redirectPath) {
            params.redirectPath = "/dashboard";
        }
        if (params.userId) {
            const formattedParams = new URLSearchParams({
            userId: params.userId
            }).toString();
            params.redirectPath = `${params.redirectPath}?${formattedParams}`;
        }
        if (params.authToken) {           
            fetch( new URL("https://id.unisot.id/api/users/profile"), {
                method: "GET",
                headers: {
                    authorization:`Bearer ${params.authToken}`
                }
                })
            .then((res) => {
                if(res.ok) {
                  return res.json();
                } else {
                  throw Error(`Request rejected with status ${res.status}`);
                }
            })
            .then((result) => {
                const jsonValue = JSON.stringify({
                    authToken: params.authToken,
                    userProfile: result,
                });
                return ApiStorage.setSessionToken(jsonValue)
            })
            .then(() => {
                props.history.push(params.redirectPath);
            })
        } else {
            props.history.push(params.redirectPath);
        }
    }, [props.history, props.location.search]);
    return <Loading loading={true}/>;
};

export default RedirectPage;