/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import ApiStorage from "../../../../../app/ApiStorage";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  const getRedirectUserData = async () => {
    try {
      const user = await ApiStorage.getSessionToken()
      const userJson = JSON.parse(user)
      if(userJson.userProfile){
        const { authToken } = userJson
        const { _id: userId } = userJson.userProfile

        return Promise.resolve({ authToken, userId })
      }
    } catch (e) {
      console.error(e, '<--- Error getting user token')
    }
  }

  const pressedGoToSDC = async () => {
    try {
      const { userId, authToken } = await getRedirectUserData()
      window.open(`https://sdc.unisot.id/auth/redirect-page?userId=${userId}&authToken=${authToken}`)
    } catch (e) {
      console.error(e, '<--- Error navigating to SDC')
    }
  }

  const pressedGoToPRP = async () => {
    try {
      const { userId, authToken } = await getRedirectUserData()
      window.open(`https://provenance.unisot.id/auth/redirect-page?userId=${userId}&authToken=${authToken}`)
    } catch (e) {
      console.error(e, '<--- Error navigating to PRP')
    }
  }

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li className="menu-section ">
            <h4 className="menu-text">ACCOUNT MANAGEMENT</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")}/>
            </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

           {/*begin::1 Level*/}
           <li
              className={`menu-item ${getMenuItemActive("/services", false)} menu-services`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/services">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}/>
            </span>
              <span className="menu-text">Services</span>
            </NavLink>
          </li>

            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/billing", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/billing">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")}/>
            </span>
              <span className="menu-text">Billing</span>
            </NavLink>
          </li>

          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/profile", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/profile">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
            </span>
              <span className="menu-text">Profile</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          <li className="menu-section ">
            <h4 className="menu-text">PRODUCT PROVENANCE</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/*begin::1 Level*/}
          <li
            className={`menu-item`}
            aria-haspopup="true"
          >
            <a className="menu-link" href="#" onClick={pressedGoToPRP}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">My Workspace</span>
              <span className="svg-icon menu-icon">
                <ExitToAppIcon />
              </span>
            </a>
          </li>
          {/*end::1 Level*/}
          <li className="menu-section ">
            <h4 className="menu-text">SDC</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/*begin::1 Level*/}
          <li
            className={`menu-item`}
            aria-haspopup="true"
          >
            <a className="menu-link" href="#" onClick={pressedGoToSDC}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">Dashboard</span>
              <span className="svg-icon menu-icon">
                <ExitToAppIcon />
              </span>
            </a>
          </li>
          {/*end::1 Level*/}
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
