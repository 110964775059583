/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import ApiStorage from "../../../../../app/ApiStorage";

export function UserProfileDropdown() {  
  const [profile, setProfile] = useState({});

  const getUserData = useCallback(async () => {
      try {
          const userData = await ApiStorage.getSessionToken();
          const transformedData = JSON.parse(userData);
          const { userProfile } = transformedData;
          setProfile(userProfile)
      } catch (error) {
          console.log(error)
      }
  }, [])

  useEffect(() => {
      getUserData()
  }, [getUserData]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{" "}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {profile.email}
          </span>
          <span className="symbol symbol-35 symbol-light-success">
            <span className="symbol-label font-size-h5 font-weight-bold">
              {profile.email}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
    </Dropdown>
  );
}
