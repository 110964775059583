import React, {useEffect} from "react";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import ApiStorage from "../../../ApiStorage";
import { loginActions } from "api/APIService";
import { useDispatch } from "react-redux";

/*class Logout extends Component {
  componentDidMount() {
    console.log(this.props)
    this.props.logout();
    ApiStorage.clearSessionToken();
  }

  render() {
    const getToken = () => {
      return ApiStorage.getSessionToken()
    }
    console.log(getToken.length > 0)
    return getToken.length > 0  ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
  }
}
*/

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    ApiStorage.clearSessionToken();
    dispatch(loginActions.setLogoutFlag(true));
    dispatch(loginActions.logout());
  }, [dispatch]);

  const getToken = () => {
    return ApiStorage.getSessionToken()
  }
  return (
    getToken.length > 0  ? <LayoutSplashScreen /> : <Redirect to="/auth" />
  )
};

export default Logout;/*connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);*/


