// Tour steps
const TOUR_STEPS = [
    {
        target: ".menu-services",
        content: "Access the store and your services",
        disableBeacon: true // This makes the tour to start automatically without click
    },
    {
        target: ".statistics-container",
        content: "Number of your active sessions."
    },
    {
        target: ".select-service",
        content: "You can select a service here"
    },
    /*{
        target: ".service-buy",
        content: "Buy a service"
    }*/
];


export const INITIAL_STATE = {
    key: new Date(), // This field makes the tour to re-render when we restart the tour
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS
};

// Reducer will manage updating the local state
export const tourReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "START":
            return { ...state, run: true };
        case "RESET":
            return { ...state, stepIndex: 0 };
        case "STOP":
            return { ...state, run: false };
        case "NEXT_OR_PREV":
            return { ...state, ...action.payload };
        case "RESTART":
            return {
                ...state,
                stepIndex: 0,
                run: true,
                loading: false,
                key: new Date()
            };
        case "SERVICE_START":
            return {
                ...state,
                stepIndex: 3,
                run: true,
                loading: false,
                key: new Date()
            };
        default:
            return state;
    }
};

