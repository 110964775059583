/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import ApiStorage from "./ApiStorage";
import RedirectPage from "./pages/RedirectPage";


export function Routes() {
    /*const {isAuthorized} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );*/

    // needed for component update
    const { loginRes, logoutFlag } = useSelector(state => ({
        loginRes: state.login.loginRes,
        logoutFlag: state.login.logoutFlag,
    }), shallowEqual);

    return (
        <Switch>
            <Route exact path="/auth/redirect-page" component={RedirectPage} />

            {(ApiStorage.getSessionToken() === null && window.location.pathname !== "/auth/redirect-page") ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Redirect from="/auth" to="/" />
                )}

            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={Logout} />

            {(ApiStorage.getSessionToken() === null && window.location.pathname !== "/auth/redirect-page") ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login" />
            ) : (
                    <Layout>
                        <BasePage />
                    </Layout>
                )}
        </Switch>
    );
};
