const tokenKeyName = 'unisot- auth';
const tokenStorage = {
    cookie: "COOKIE",
    localStorage: "LOCAL_STORAGE",
    mobileStorage: "MOBILE_STORAGE"
};

// default for mobile
let tokenStorageType = tokenStorage.localStorage;

// token var
let sessionToken = undefined;

const setTokenStorageType = (storageType) => {
    tokenStorageType = storageType;
};

const setSessionToken = async (token) => {
    try {
        if (tokenStorageType === tokenStorage.cookie) {
            sessionStorage.setItem(tokenKeyName, token);
        }
        if (tokenStorageType === tokenStorage.localStorage) {
            localStorage.setItem(tokenKeyName, token);
        }
    } catch (err) {
        console.log(`Error save token in localStorage: ${err}`)
    }
}

const getSessionToken = () => {
    try {
        if (sessionToken) {
            // console.log('saved token')
            return sessionToken;
        } else {
            if (tokenStorageType === tokenStorage.cookie) {
                return sessionStorage.getItem(tokenKeyName);
            } else if (tokenStorageType === tokenStorage.localStorage) {
                return localStorage.getItem(tokenKeyName);
            }
        }
    } catch (err) {
        console.log(`Error get token from localStorage: ${err}`)
    }
}

const clearSessionToken = () => {
    sessionToken = undefined;
    try {
        if (tokenStorageType === tokenStorage.cookie) {
            sessionStorage.removeItem(tokenKeyName)
        } else if (tokenStorageType === tokenStorage.localStorage) {
            localStorage.removeItem(tokenKeyName)
        }
    } catch (err) {
        console.log(`Error remove token from localStorage: ${err}`)
    }
}

const ApiStorage = {
    tokenStorage,
    setTokenStorageType,
    setSessionToken,
    getSessionToken,
    clearSessionToken
};

export default ApiStorage;
