/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useState, useCallback, useEffect} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {UserProfileDropdown} from "./dropdowns/UserProfileDropdown";
import ApiStorage from "../../../../app/ApiStorage";


export function QuickUserToggler() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  const [profile, setProfile] = useState({});

  const getUserData = useCallback(async () => {
      try {
          const userData = await ApiStorage.getSessionToken();
          const transformedData = JSON.parse(userData);
          const { userProfile } = transformedData;
          setProfile(userProfile)
      } catch (error) {
          console.log(error)
      }
  }, [])

  useEffect(() => {
      getUserData()
  }, [getUserData]);

  return (<>
        {layoutProps.offcanvas && (<OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}
        >
          <div className="topbar-item">
            <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                 id="kt_quick_user_toggle">
              <>
                {
                   Object.entries(profile).length > 0 &&
                   <>
                    <span className="text-primary font-weight-bolder font-size-base d-none d-md-inline mr-3">
                      {profile.email}
                    </span>
                    <span className="symbol symbol-35 symbol-light-primary">                
                      <span className="symbol-label font-size-h5 font-weight-bold">{profile.email.charAt(0)}</span>
                    </span>
                  </>
                }
              </>
            </div>
          </div>
        </OverlayTrigger>)}

        {!layoutProps.offcanvas && (<UserProfileDropdown/>)}
      </>
  );
}
