import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";

// app specific

import dashboardReducer from "api/statistics/reducers";
import loginReducer from "api/login/reducers";
import accountManagementReducer from "api/account-management/reducers";
import permissionsReducer from "api/permissions/reducers";

import { statisticsSagas } from "api/statistics/sagas";
import { accountManagementSagas } from "api/account-management/sagas";
import { permissionsSagas } from "api/permissions/sagas";

import { tourReducer } from "../app/pages/tourState";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  dashboard: dashboardReducer,
  login: loginReducer,
  accountManagement: accountManagementReducer,
  permissions: permissionsReducer,
  tour: tourReducer
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    ...statisticsSagas,
    ...accountManagementSagas,
    ...permissionsSagas
  ]);
}
