import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";

const ProfilePage = lazy(() => import("./modules/UserProfile/UserProfilePage"));
const DashboardPage = lazy(() => import("./pages/dashboard/DashboardPage"));
const ServicesPage = lazy(() => import("./pages/services-page/ServicesPage"));
const ServiceDetailsPage = lazy(() => import("./pages/services-page/ServiceDetailsPage"));
const BillingPage = lazy(() => import("./pages/billing/BillingPage"));
const ServiceLearnMorePage = lazy(() => import("./pages/services-page/ServiceLearnMorePage"));


export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<></>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard" />
                }
                <ContentRoute path="/dashboard" component={DashboardPage} />
                <Route exact path="/services" component={ServicesPage} />
                <Route exact path="/services/:id" component={ServiceDetailsPage} />
                <Route exact path="/services/learn-more/:id" component={ServiceLearnMorePage} />
                <Route exact path="/billing" component={BillingPage} />
                <Route path="/profile" component={ProfilePage} />

                <Redirect to="error/error-v1" />
            </Switch>
        </Suspense>
    );
};
